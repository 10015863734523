<template @click="no">
  <AppBar>
    <!-- 按钮 -->
    <div class="nav">
      <div></div>
      <el-button
        v-show="getRole_id == 1"
        @click="up"
        class="right"
        type="info"
        plain
        round
        size="medium"
        >发布公告</el-button
      >
    </div>
    <!-- 内容 -->
    <div class="wrap">
      <el-collapse class="wrap-list">
        <el-collapse-item v-for="(item, index) in list" :title="item.title">
          <div class="wrao">
            {{ item.body }}
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 弹窗 -->
    <div class="box" v-show="show">
      <div class="box-bg" @click="hieed"></div>
      <div class="box-wrap">
        <div class="last-box">
          <el-input
            type="textarea"
            placeholder="请输入标题"
            v-model="text"
            maxlength="300"
            show-word-limit
            class="inp"
            :autosize="{ minRows: 3 }"
          >
          </el-input>
          <div style="margin: 20px 0"></div>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="1000"
            show-word-limit
            :autosize="{ minRows: 8 }"
          >
          </el-input>
          <el-button
            type="primary"
            class="btn"
            native-type="submit"
            @click="submit_con(text, textarea)"
            >发布</el-button
          >
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="page">
      <el-pagination
        :hide-on-single-page="false"
        layout="total, prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        style="margin-top: 20px"
        background
        :page-count="total"
        :page-size="20"
        :total="z_page"
      >
      </el-pagination>
    </div>
  </AppBar>
</template>
<script>
import AppBar from "../../components/AppBar";

export default {
  data() {
    return {
      // activeNames: ['1']
      show: false,
      text: "",
      currentPage: 1,
      title: "",
      textarea: "",
      list: [],
      total: 0,
      z_page: 0,
    };
  },
  created() {
    this.$common.getInterface(
      "/api/v1.notice/getNoticeList",
      {
        page: 1,
      },
      this,
      (res) => {
        console.log(res);
        this.list = res.data.data;
        this.total = res.data.last_page;
        this.z_page = res.data.total;
      }
    );
  },
  computed: {
    getRole_id() {
      return sessionStorage.getItem("role_id") * 1;
    },
  },
  methods: {
    handleCurrentChange(e) {
      console.log(e);
      this.$common.getInterface(
        "/api/v1.notice/getNoticeList",
        {
          page: e,
        },
        this,
        (res) => {
          console.log(res);
          this.list = res.data.data;
          this.total = res.data.last_page;
          this.z_page = res.data.total;
          console.log(this.z_page);
        }
      );
    },
    submit_con(title, body) {
      this.$common.postInterface(
        "/api/v1.notice/postNotice",
        { title: "公告："+title, body: body },
        this,
        (res) => {
          console.log(res);
          setmas("请求成功", "success", this);
          this.show = false;
          this.$router.go(0);
        }
      );
    },
    no() {
      this.show = false;
    },
    hieed() {
      this.show = false;
    },
    up() {
      this.show = true;
      // this.$common.postInterface(
      //   "/api/v1.notice/postNotice",
      //   {title:1,body:1},
      //   this,
      //   (res) => {
      //     console.log(res);

      //   }
      // );
    },
  },
  components: {
    AppBar,
    // Dialog: resolve => {
    //   require(["./Dialog"], resolve);
    // } //懒加载
  },
};
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.wrao {
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
}
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;
  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
<style lang="less" scoped>
@deep: ~">>>";
@{deep}.wrap {
  margin-top: 18px;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background-color: white;
  overflow: hidden;
}
.btn {
  width: 230px;
  margin-top: 30px;
  transform: translate(50%);
}
.last-box {
  width: 90%;
  margin: auto;
}
.inp {
  margin-top: 30px;
}
.box-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(77, 74, 74, 0.3);
}
.box-wrap {
  width: 500px;
  height: 450px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nav {
  display: flex;
  justify-content: space-between;
}
.wrap-list {
  width: 90%;
  margin: 0 auto;
}
</style>
